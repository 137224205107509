@import "fonts";
@import "sign";
@import "signs-module";
@import "advertisement";
@import "attribution";

:root {
  --background-image: url("../public/images/horoscope-bg.jpg");
}
html {
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
}

body {
  background-image: url("../public/images/horoscope-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0 auto;
}

.main {
  max-width: 544px;
  margin: auto;
  text-align: center;
  margin-top: 80px;
  &__title {
    font-family: new-spirit;
    font-size: 40px;
    @media screen and (max-width: 500px) {
      font-size: 32px;
    }
    @media screen and (max-width: 400px) {
      font-size: 30px;
    }
    font-weight: 600;
    color: #A188EB;
    text-align: center;
    margin-bottom: 20px;
    &:before {
      content: url("../public/images/icons/title-left.svg");
      margin-right: 8px;
    }
    &:after {
      content: url("../public/images/icons/title-right.svg");
      margin-left: 8px;
    }
  }
  &__blurb {
    color: #fff;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 40px;
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
  }
}

.newsletter {
  margin: 20px auto 100px;
}

a.season {
  text-decoration: none;
}

.season {
  &__title {
    color: #fff;
    text-shadow: 0px 0px 4px #000;
    font-family: new-spirit-condensed;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: -1.8px;
    margin-bottom: 10px;
    text-decoration: none;
  }

  &__sign {
    text-transform: capitalize;
  }
  &__constellation {
    margin-bottom: 10px;
  }
}
