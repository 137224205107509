.attribution {
  margin: 60px auto 0;
  color: #fff;
  font-size: 16px;
  a {
    color: #A188EB;
    font-weight: 700;
  }
  .no-newsletter & {
    margin-bottom: 100px;
  }
}
