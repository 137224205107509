.signs-module {
  display: grid;
  text-align: center;
  row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: auto;
  &.rows-of-three {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    .signs-module__overlay {
      left: 7px;
    }
    @media screen and (min-width: 901px) {
      row-gap: 24px;
      .signs-module__overlay,
      .signs-module__img {
        display: none;
      }
      .signs-module__item {
        &:hover {
          color: #A188EB;
        }
      }
    }
  }
  &.rows-of-four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .signs-module__overlay {
      left: 33px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      .signs-module__overlay {
        left: 7px;
      }
    }
  }
  &__container {
    position: relative;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-family: Montserrat;
  }
  &__item {
    width: 89px;
    height: 107px;
    text-align: center;
    width: 89px;
    height: 107px;
    &.current {
      .signs-module__overlay {
        &:hover {
          opacity: .5;
        }
      }
    }
  }
  &__overlay {
    width: 72px;
    height: 72px;
    top: -18px;
    position: absolute;
    background: url("../public/images/hover-effect/hover-circle.svg");
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 1;
    transition: transform 0.2s ease-in-out;
    @media screen and (max-width: 900px) {
      display: none; // no hover state on smaller screens
    }
    &:hover {
      opacity: 1;
      transition: transform 0.2s ease-in-out;
      animation: rotate 8s linear infinite .2s;
      + .signs-module__img {
        path {
          transform: scale(.65);
          transform-origin: center;
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
  &__img {
    max-width: 50px;
    max-height: 50px;
    path {
      transition: transform 0.3s ease-in-out;
      transform-origin: center;
    }
    .current & {
      path {
        stroke: #6d7380;
      }
    }
  }
  &__text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-block: 0; // undo h3 margin
    margin-bottom: 4px;
    .current & {
      color: #6d7380;
    }
  }
  &__date {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: -1px;
    .current & {
      color: #6d7380;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
