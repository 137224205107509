.advertisement {
  &.top_leaderboard {
    @media screen and (max-width: 500px) {
      max-width: 320px;
      min-height: 50px;
      margin: 24px auto 32px;
    }
    text-align: center;
    max-width: 970px;
    min-height: 90px;
    margin: 40px auto 32px;
  }
  &.bottom_leaderboard {
    @media screen and (max-width: 500px) {
      max-width: 320px;
      // height: 50px;
    }
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 970px;
    margin: 60px auto;
  }
  &.repeating_dynamic_display {
    position: relative;
    div {
      top: 50px;
      position: sticky;
    }
  }
  &.right_rail_flex {
    min-height: 250px;
    height: 250px;
    max-height: 250px;
    // do not allow ad to exceed 250px height:
  }
  &.desktop {
    @media screen and (max-width: 900px) {
      display: none;
    }
    text-align: center;
    max-width: 970px;
    // height: 250px;
    margin: 40px auto 32px;
  }
  &.mobile {
    @media screen and (min-width: 901px) {
      display: none;
    }
    text-align: center;
    max-width: 320px;
    min-height: 250px;
    margin: 24px auto 32px;
  }
}
