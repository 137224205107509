.recirc {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 107px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1280px) {
    max-width: calc(1120px - 40px);
  }
  &__title {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 800;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .mobile & {
      display: flex;
      flex-direction: column;
    }
    row-gap: 24px;
    column-gap: 32px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

.card {
  max-width: 256px;
  @media screen and (max-width: 900px) {
    margin: 0 auto 20px;
  }
  .mobile & {
    display: flex;
    height: 78px;
    min-width: 100%;
  }
  &__img__link, &__img img {
    .mobile & {
      min-height: 78px;
      min-width: 78px;
      margin-right: 16px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    color: #FFF;
    .mobile & {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__label {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #FFF;
    margin: 4px 0 8px;
    text-transform: uppercase;
    .mobile & {
      font-size: 13px;
      line-height: 14px;
      margin-top: 0;
    }
  }
}
